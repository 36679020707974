import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../directives/header'
import Footer from '../directives/footer'
import toast, { Toaster } from 'react-hot-toast';
import config from "../coreFIles/config";
import Cookies from 'js-cookie';
import { getProductDetailAction, addToCartQRProductAction } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessfwMember')) ? [] : JSON.parse(Cookies.get('loginSuccessfwMember'));
const isLoginDataEmpty = Object.keys(loginData).length === 0;
const customStyles = {
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const QRAddToCartProduct = () => {
	
	const { id } = useParams();
	const [isvalidproduct, setisvalidproduct] = useState(1);

	const QRAddToCarthasRun = useRef(false);

		useEffect(() => {
			getProductDetailsAPI();
		}, []);

		const getProductDetailsAPI = async () => {
		if (QRAddToCarthasRun.current) return;

		let res = await getProductDetailAction({ product_id: id });

		if (res.success && !QRAddToCarthasRun.current) {
			QRAddToCarthasRun.current = true; 
			let data = res.data;
			Cookies.set('QRAddToCartProductDetail', JSON.stringify(res.data));

			if (Object.keys(data).length > 0) {
				setisvalidproduct(1);
				
				if (isLoginDataEmpty) {
					window.location.href = `${config.baseUrl}login`;
				  }
				  else
				  {
						let QRAddToCartProductDetail	=	data;
					  
						let res = await addToCartQRProductAction({product_id:QRAddToCartProductDetail.id,hobson_part:QRAddToCartProductDetail.hobson_part});
						
						if (res.success) {	
							Cookies.remove('QRAddToCartProductDetail');	
						}
												
						Cookies.set('QRAddToCartMsg', res.msg);
						window.location.href = `${config.baseUrl}`;
				  }				
				
			} else {
				setisvalidproduct(0);
			}
		}
		
	};
	
	return(
		<>
		<div className="qr-process-container " >
			<div className="container">
				<div className="text-center">
				{isvalidproduct == 1 ? (
					<p className="msg">Adding to cart...</p>
					):(
					<p className="msg">Something went wrong...</p>	
					)
				}		
				</div>
			</div>	
		</div>	
		</>
		)
}
export default QRAddToCartProduct;
