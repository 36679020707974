import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config'
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie'
import { } from '../Action/action'

const Header = () => {
	const loginData = (!Cookies.get('loginSuccessfwMember')) ? [] : JSON.parse(Cookies.get('loginSuccessfwMember'));
		
	useEffect(() => {
	//~ if (!loginData || loginData == '') {
		//~ window.location.href = `${config.baseUrl}/login`;
	  //~ }	
		
	},[])
	
	
	return (
		<>
		<div className="top-header">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-10 ">
						<div className="porter-logo porter-logo-new">
							<Link to={`${config.baseUrl}`}><img src={`${config.baseUrl}images/porterlogo.png`} /></Link>
							
							</div>
							   </div>
							<div className="col-2 logout">
							
							</div>
					
				</div>
			</div>
		</div>
			
        </>
    )
   
}
export default Header;
